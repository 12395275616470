<template>  
  <center>

    <v-alert
      style="position: fixed; z-index:2; absolute; left: 0; right: 0; margin-left: auto; margin-right: auto; width: 300px;"
      dark
      class="rounded-lg mt-3 title"
      dismissible
      color="success"
      v-model="AlertBerhasilAbsen"
      transition="slide-y-transition"
    >
      Terima Kasih
    </v-alert>

    <div class="cambox" style="height: 400px; width: 100%;">
      <!-- <p><b>{{ Kategori.kategori }}</b></p> -->
      <p class="error" v-if="noFrontCamera">
        You don't seem to have a front camera on your device
      </p>

      <p class="error" v-if="noRearCamera">
        You don't seem to have a rear camera on your device
      </p>

      <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" v-if="StatusCam ==  undefined && StatusCamPulang == undefined">
        <v-btn small fab depressed @click="switchCamera"><v-icon>mdi-camera-retake-outline</v-icon></v-btn>
        <v-chip class="ma-2" v-text="Kategori.kategori"></v-chip>
      </qrcode-stream>

      <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" v-if="StatusCam ==  true">
        <v-btn small fab depressed @click="switchCamera"><v-icon>mdi-camera-retake-outline</v-icon></v-btn>
        <v-chip class="ma-2" v-text="Kategori.kategori"></v-chip>
      </qrcode-stream>

      <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" v-if="StatusCamPulang ==  true">
        <v-btn small fab depressed @click="switchCamera"><v-icon>mdi-camera-retake-outline</v-icon></v-btn>
        <v-chip class="ma-2" v-text="Kategori.kategori"></v-chip>
      </qrcode-stream>
      <!-- <p><b>{{ Kategori.kategori }}</b></p> -->
      <!-- <v-btn @click="ceklokasi()">cek lokasi</v-btn> -->
      <!-- <p class="decode-result"><b>{{ result }}</b></p> -->
    </div>
  </center>
</template>

<script>
import Vue from "vue";
import VueQrcodeReader from "vue-qrcode-reader";
import api from "@/services/http"; 
Vue.use(VueQrcodeReader);


export default {
  props: ['StatusCam','StatusCamPulang','Kategori'],
  data () {
    return {
      AlertBerhasilAbsen: false,
      camera: 'rear',
      result:null,
      ScanMasuk:null,
      ScanPulang:null,
      latdia:0,
      longdia:0,
      lat:sessionStorage.getItem('lat'),
      long:sessionStorage.getItem('long'),
      Jarak:0,
      noRearCamera: false,
      noFrontCamera: false,
      lokasi:[],
      karyawan:[],
    }
  },
  mounted(){
    this.karyawan = JSON.parse(localStorage.getItem('karyawan'))
    this.getcabang()
    console.log('ssa',this.Cam)
  },
  watch:{
    AlertBerhasilAbsen(){
      this.AlertItem()
    },
    StatusCam(){
      console.log('true',this.StatusCam)
    },
    StatusCamPulang(){
      console.log('x',this.StatusCamPulang)
    },
    Cam(){
      console.log('xas',this.Cam)
    }
  },
  computed:{
    Cam(){
      return this.StatusCam == true ? 'ScanMasuk' : this.StatusCamPulang == true ? 'ScanPulang' : null
    }
  },

  methods: {
    tutup(v){
      console.log(v)
      Vue.prototype.$ScanMasukToday = this.ScanMasuk
      Vue.prototype.$ScanPulangToday = this.ScanPulang
      this.$emit('tutup',v)
    },
    getcabang(){
        api.get('/lokasi?token='+this.token,
    // { headers: {"Authorization" : `Bearer ${this.token}`} }
    ).then(
        res=>{
          // this.listlokasi = res.data
          this.lokasi = res.data.find(f => f.kodecabang == this.karyawan.Cabang)
          // this.Jarak = this.ResultJarak()
          console.log('l',this.lokasi)
          console.log('k',this.karyawan)
        })
        
      },
    switchCamera () {
      switch (this.camera) {
        case 'front':
          this.camera = 'rear'
          break
        case 'rear':
          this.camera = 'front'
          break
      }
    },
    ToastErr(data){
      this.$swal.fire({
      toast: true,
      icon: 'error',
      title: data,
      animation: false,
      showCloseButton: true,
      position: 'top-center',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true})
    },
    Toast(data){
      this.$swal.fire({
      toast: true,
      icon: 'success',
      title: data,
      animation: false,
      showCloseButton: true,
      position: 'top-right',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true})
    },
    ceklokasi(){
      alert(this.ResultJarak())
    },
    ResultJarak(){
      return this.getJarak(sessionStorage.getItem('lat'),sessionStorage.getItem('long'),this.lokasi.latitude,this.lokasi.longitude)
    },
    async onDecode (result) {
      // -0.5146435
      // 117.1310156
      // this.lokasi.latitude
      // this.lokasi.longitude
      this.result = JSON.parse(result)
      // this.result = result
      console.log('result',this.result)
      let latcek = String(this.lat)
      latcek = (latcek+"").split(".")[1]
      let longcek = String(this.long)
      longcek = (longcek+"").split(".")[1]
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      console.log('latcek',latcek,'+longcek+',longcek)
      //cek latitude fake gps atau bukan
    //   let data = navigator.userAgent.split(/\s*[;)(]\s*/);
    //  if(latcek.length >= 10 && data[1] == 'Linux'){
    //    this.ToastErr("Lokasi anda tidak tepat")
    //  }else{
      let jarak = this.getJarak(this.lat,this.long,this.result.lat,this.result.long)
      this.Jarak = jarak
      let maxjarak = 100
      if (jarak <= maxjarak) {
        if (this.result.NRK == null) {
          this.result.NRK = this.karyawan.NRK
          this.result.Latitude = this.lat
          this.result.Longitude = this.long
          this.result.Status = this.Cam
          this.result.site = this.result.Lokasi != 'Site' ? this.result.Lokasi : this.lat+','+this.long
          this.result.KategoriAbsen = this.Kategori.kategori
          this.result.JamMasuk = this.Kategori.jammasuk
          this.result.JamPulang = this.Kategori.jampulang
          this.result.Timezone = timezone
        }
        console.log(this.result)
        // alert('ok detekted')
        api.post("/absencodeqr", this.result)
          .then((res) => {
            console.log(res);
            res.data.absen.Scan = this.result.Status
            // this.ScanMasuk = res.data.absen
            if (this.result.Status == 'ScanMasuk') {
              this.ScanMasuk = res.data.absen
            }else if(this.result.Status == 'ScanPulang'){
              this.ScanPulang = res.data.absen
            }
              this.tutup('1')
              this.Toast("Terima Kasih")
              // this.AlertBerhasilAbsen = true;
            this.result = null
            // this.Toast("Terima Kasih")
          })
          .catch((err) => {
            console.log(err);
          });
        }else if (jarak > maxjarak) {
        this.Jarak = jarak - maxjarak
        this.Jarak = this.Jarak > 999 ? String(this.Jarak)[0]+" Kilometer" : this.Jarak+" Meter"
        this.ToastErr("Anda "+this.Jarak+" Terlalu Jauh Dari Lokasi")
      // }else{
      //   this.ToastErr("Silahkan Coba Lagi ")
      // }
    }
    //  }else{
    //    this.ToastErr("Lokasi Anda Belum Tepat Mohon Coba Lagi")
    //  }
    },

    getJarak(lat1,lon1,lat2,lon2) {
        function deg2rad(deg) {
          return deg * (Math.PI/180)
        }
        var R = 6371; // Radius bumi dalam kilometer
        var KmToMeters = 1000; // kilometer ke meter
        var dLat = deg2rad(lat2-lat1);  // degre ke radius untuk latitude
        var dLon = deg2rad(lon2-lon1); // degre ke radius untuk longitude
        var a = 
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
          Math.sin(dLon/2) * Math.sin(dLon/2)
          ; 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c * KmToMeters; // hitung untuk meter
        d = parseInt(d) //membulatkan hasil
        return d;
      },

    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        const triedFrontCamera = this.camera === 'front'
        const triedRearCamera = this.camera === 'rear'

        const cameraMissingError = error.name === 'OverconstrainedError'

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true
        }

        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
button {
  position: absolute;
  left: 10px;
  top: 10px;
}

.error {
  color: red;
  font-weight: bold;
}
.cambox{
    width:850px;
    height: 500px;
}
</style>